<template>
  <div class="page-under-construction">

    <div class="page-bg"></div>

    <div class="content">
      <!-- <h2 class="message" title="development">Nos estamos renovando!</h2>
      <h2 class="message">Pronto estaremos de regreso...</h2> -->

      <a href="https://www.facebook.com/ivanjimenezfotos">
        <img class="logo" src="@/assets/logo.png" alt="logo">
        <img class="logo-text" src="@/assets/logo-text.png" alt="logo text">
      </a>

      <!-- <audio class="sound-bg" controls autoplay="true">
        <source src="@/assets/radical-face-nikon.mp3" type="audio/mpeg">
      </audio> -->
    </div>

  </div>
</template>

<script>
import '@/components/global/style.scss'
export default {
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Krona+One&family=Righteous&display=swap");

html {
  height: 100vh;
}

.page-bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: url("../assets/background.jpg") center no-repeat;
  background-size: cover;
  // animation: bg-slide 30s infinite;
}

.content {
  position: absolute;
  z-index: 1;
  width: 100vw;
  padding: 3rem 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
}

.message {
  text-shadow: 0 0 0.4rem #000;
  &:nth-child(1) {
    top: 10vh;
    color: #f1f3c4;
    font-family: "Krona One", sans-serif;
    font-size: 30pt;
  }
  &:nth-child(2) {
    margin-top: 35vh;
    color: #fff;
    font-family: "Righteous", cursive;
    font-size: 35pt;
    font-style: italic;
  }
}

.logo {
  display: block;
  margin: 0 auto;
  max-width: 10rem;
  &:hover {
    transform: scale(1.1);
  }
}
.logo-text {
  display: block;
  margin: 0 auto;
  max-height: 5rem;
}

.sound-bg {
  margin-top: 10rem;
}

@keyframes bg-slide {
  0%,
  100% {
    transform: scale(1);
  }
  40%,
  60% {
    transform: scale(1.1);
  }
}

@media only screen and (orientation: portrait) {
  .page-bg {
    background-position-x: 80%;
  }
}
</style>
